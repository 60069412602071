@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_ExtraCondensed-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_Condensed-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans_SemiCondensed-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('../fonts/NotoSans/static/NotoSans-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

.active-modal {
  overflow: hidden;
}
